import getConfig from 'next/config'
import { Region } from '@constants/regions'

const { publicRuntimeConfig } = getConfig()

export const ENVIRONMENT = {
    APP_URL: publicRuntimeConfig?.APP_URL,
    APP_ENV: publicRuntimeConfig?.APP_ENV,
    RELEASE: publicRuntimeConfig?.RELEASE,
    REGIONS: ((publicRuntimeConfig?.REGIONS || '').split(';') as Region[]),
    API_BASE_URL: publicRuntimeConfig?.API_BASE_URL,
    APP_DEFAULT_LOCALE: publicRuntimeConfig?.APP_DEFAULT_LOCALE,
    CARCULATOR_URL: publicRuntimeConfig?.CARCULATOR_URL,
    SENTRY: {
        ENABLED: publicRuntimeConfig?.SENTRY_DSN !== undefined,
        DSN: publicRuntimeConfig?.SENTRY_DSN,
    },
    AUTH0: {
        AUDIENCE: publicRuntimeConfig?.AUTH0_AUDIENCE,
        CLIENT_ID: publicRuntimeConfig?.AUTH0_CLIENT_ID,
        CUSTOM_DOMAIN: publicRuntimeConfig?.AUTH0_CUSTOM_DOMAIN,
    },
    FACADE_V1: {
        URL: publicRuntimeConfig?.CALCULATOR_FACADE_URL,
        TOKEN: publicRuntimeConfig?.CALCULATOR_FACADE_TOKEN,
    },
    FACADE_V2: {
        URL: publicRuntimeConfig?.CALCULATOR_FACADE_V2_URL,
    },
    IMAGE_SERVER: {
        URL: publicRuntimeConfig?.IMAGE_SERVER_URL,
    },
    AUTOTELEX: {
        METHOD: publicRuntimeConfig?.AUTOTELEX_METHOD,
    },
    LEASE_OBJECT_LOADER: {
        TARGETS: (publicRuntimeConfig?.LEASE_OBJECT_LOADER_TARGETS || '').split(';'),
    },
}
