import { css } from 'styled-components'
import { PaginationProps, PaginationSpaceElement, WithThemeProp } from '@tblg/components'

export const Pagination = (props: PaginationProps & WithThemeProp) => css`
    align-items: center;
    display: flex;
    justify-content: center;

     ${/* sc-sel */PaginationSpaceElement} {
        color: ${ props.theme.colors.numberColor };
        font-size: 1.5rem;
        padding: 1rem;
    }
`
