import { AlertProps, WithThemeProp } from '@tblg/components'
import { css } from 'styled-components'
import { MixinState } from './mixins/State'

export const Alert = (props: AlertProps & WithThemeProp) => css`
    border-radius: 0.25em;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 1rem 2rem;
    margin-bottom: 2rem;

    > p {
        margin: 0;
    }

    > i {
        font-size: 2.4rem;
        line-height: 1.5;
        margin-right: 0.4em;
    }

    /* stylelint-disable */
    /* Mixins */
    ${ MixinState(props) }
`
