import { ButtonSize, ButtonProps, WithThemeProp } from '@tblg/components'
import { css, FlattenSimpleInterpolation } from 'styled-components'

export const MixinSize = (props: ButtonProps & WithThemeProp): FlattenSimpleInterpolation => {
    switch (props.size) {
        case ButtonSize.large:
            return css`
                font-size: 1.25rem;
            `

        case ButtonSize.small:
            return css`
                font-size: 0.875rem;
            `

        case ButtonSize.default:
        default:
            return css`
                font-size: 1rem;
            `
    }
}
