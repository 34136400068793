import { ContentType, ButtonState, WithThemeProp } from '@tblg/components'
import { css, FlattenSimpleInterpolation } from 'styled-components'

export const MixinContentType = (props: ButtonState & WithThemeProp): FlattenSimpleInterpolation => {
    switch (props.contentType) {
        case ContentType.iconLeft:
            return css`
                i {
                    margin-right: 0.5rem;
                }
            `
        case ContentType.iconRight:
            return css`
                  i {
                    margin-left: 0.5rem;
                }
            `
        case ContentType.iconOnly:
            return css`
                height: 3em;
                padding: 0;
                width: 3em;
                display: flex;
                flex-direction: column;
                justify-content: center;
                
                i {
                    margin: 0 auto;
                }
            `
        default:
            return css``
    }
}
