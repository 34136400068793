import Head from 'next/head'
import { BackgroundElement, ContentWrapper } from './Style'
import { FC } from 'react'

interface Props {
    children: object
}

export const SplashScreen: FC<Props> = ({ children }) => (
    <BackgroundElement>
        <Head>
            <title>leaseDoc</title>
        </Head>
        <ContentWrapper>
            { children }
        </ContentWrapper>
    </BackgroundElement>
)
