import { ButtonProps, WithThemeProp } from '@tblg/components'
import { css } from 'styled-components'
import { MixinContentType } from './mixins/ContentType'
import { MixinSize } from './mixins/Size'
import { MixinVariant } from './mixins/Variant'

export const Button = (props: ButtonProps & WithThemeProp) => css`
    border: 1px solid transparent;
    border-radius: 0.25em;
    cursor: pointer;
    display: flex;
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    line-height: 1.5;
    margin: 0;
    outline: none;
    padding: 0.65em 1em;
    transition: color 300ms ease, border-color 300ms ease, background-color 300ms ease;
    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    /* stylelint-disable */
    /* Mixins */
    ${ MixinContentType(props) }
    ${ MixinVariant(props) }
    ${ MixinSize(props) }
`
