import { WithThemeProp } from '@tblg/components'
import { css } from 'styled-components'
import { spin } from '@theme/animations/spin'

export const Spinner = (props: WithThemeProp) => css`
    height: 3em;
    position: relative;
    width: 3em;

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        animation: ${ spin } .5s linear infinite;
        border: 2px solid ${ props.theme.colors.white };
        border-right: 0.1em solid transparent;
        border-radius: 50%;
        box-sizing: border-box;
        width: 3em;
        height: 3em;
    }
`
