import { WithThemeProp, TabProps } from '@tblg/components'
import { css } from 'styled-components'

export const Tabs = (props: WithThemeProp) => css`
  border-bottom: 1px solid ${ props.theme.colors.gray1 };
  display: flex;
  font-weight: 400;
  list-style: none;
  margin: 0 0 2rem;
  padding: 0;
`

export const Tab = (props: WithThemeProp & TabProps) => css`
  color: ${ props.theme.colors.gray7 };
  cursor: pointer;
  
  ${ props.selected && css`
    border-bottom: 1px solid ${ props.theme.colors.quaternary };
  ` }
    
  padding: 1rem 2rem;
  
  &:hover {
    color: ${ props.theme.colors.quaternary };
    border-bottom: 1px solid ${ props.theme.colors.quaternary };
  }
`
