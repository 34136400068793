import { css } from 'styled-components'
import { PanelProps, PanelState, PanelElement, TabsElement, TabElement, WithThemeProp } from '@tblg/components'

export const Panel = (props: PanelProps & PanelState & WithThemeProp) => css`
    background-color: ${ props.theme.colors.white };
    border-radius: 0.2em;
    box-shadow: 0 0 1em 0 rgba(82, 63, 105, 0.05);
    box-sizing: border-box;

    > div {
        padding: 1rem 1.9em;
    }

    ${props.heading && css`
        > header {
            align-items: center;
            display: flex;
            padding: 1.2rem 1.9em;
            > h1, h2, h3, h4, h5, h6 {
                margin: 0;
            }
            > ul {
                display: flex;
                flex-wrap: wrap;
            }
            ${!props.collapsed && css`
                border-bottom: 1px solid ${ props.theme.colors.gray1 };
            ` }
        }
    `}

    ${props.collapsible && css`
        > header {
            position: relative;
            cursor: pointer;

            &:after {
                content: '${props.collapsed ? '+' : '-' }';
                position: absolute;
                right: 2rem;
                top: 1rem;
                color: ${ props.theme.colors.secondary };
                font-size: 2rem;
            }
        }
    ` }
    
    ${/* sc-sel */PanelElement} {
        box-shadow: none;
        background-color: ${ props.theme.colors.gray5 };
    }
    
   > header {   
        ${/* sc-sel */TabsElement} {
            border-bottom: none;
            margin: -1.2rem -1.9rem;
            ${/* sc-sel */TabElement} {
                color: ${ props.theme.colors.text };
            }
        }
    }
`
