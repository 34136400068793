export interface InvalidRequestResponseJson {
    '@context': string,
    '@type': string,
    'hydra:title': string,
    'hydra:description': string,
}

export interface InvalidRequestResponseWithConstraintViolationListJson extends InvalidRequestResponseJson {
    '@context': '/contexts/ConstraintViolationList',
    '@type': 'ConstraintViolationList',
    violations: Array<{
        message?: string,
        title?: string,
        propertyPath: string,
    }>,
}

export class RequestError extends Error {
    public response: Response

    constructor(response: Response) {
        super(`Request error: ${response.status} response. Url: ${response.url} `) // temp edit
        this.response = response
    }
}
