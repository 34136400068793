import {
    DropdownOptionListProps,
    DropdownOptionListItemProps,
    CheckboxElement,
    WithThemeProp,
} from '@tblg/components'
import { css } from 'styled-components'

export const DropdownOptionList = (props: DropdownOptionListProps & WithThemeProp) => css`
    background-color: ${ props.theme.colors.white };
    border: 1px solid ${ props.theme.colors.gray0 };
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
`
export const DropdownOptionListItem = (props: DropdownOptionListItemProps & WithThemeProp) => css`
    display: flex;
    cursor: pointer;
    padding: 0.2rem 0.5rem;
    
    ${ props.highlighted && css`
        background-color: ${ props.theme.colors.quaternary };
        color: ${ props.theme.colors.white };
    ` }
    
    ${ props.selected && css`
        font-weight: 500;
    ` }
    
    ${ CheckboxElement } {
        margin-right: 0.5rem;
    }
`
