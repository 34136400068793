import * as React from 'react'
import {
    Props,
    Request,
    GetRequest,
    PostRequest,
    PatchRequest,
    Result,
    Connection,
    ResponseFormat,
} from './Interfaces'
import { ENVIRONMENT } from '@root/environment'
import { RequestError } from './RequestError'

const serialize = (obj: object, prefix?: string): string => {
    const str = []
    let p
    for (p in obj) {
        if (obj.hasOwnProperty(p) && obj[p as keyof object] !== undefined) {
            const k = prefix ? prefix + '[' + p + ']' : p
            const v = obj[p as keyof object]
            str.push((v !== null && typeof v === 'object') ?
                serialize(v, k) :
                encodeURIComponent(k) + '=' + encodeURIComponent(v))
        }
    }
    return str.join('&')
}

const query = (request: Request): string => {
    if (request.query !== undefined) {
        return `?${serialize(request.query)}`
    }
    return ''
}

const handleResponse = async (response: Response, expected: ResponseFormat = ResponseFormat.JSON): Promise<Result> => {
    let data
    switch (expected) {
        case ResponseFormat.JSON:
            data = await response.json()
            break
        case ResponseFormat.TEXT:
            data = await response.text()
            break
        case ResponseFormat.BLOB:
            data = await response.blob()
            break
        case ResponseFormat.NONE:
            break
        default:
            throw Error('Invalid expected response format')
    }
    return {
        statusCode: response.status,
        data,
    }
}

const get = async (request: GetRequest): Promise<Result> => {
    const response = await fetch(`${ENVIRONMENT.API_BASE_URL}${request.path}${query(request)}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...request.headers,
        },
    })
    if (response.status >= 200 && response.status < 300) {
        return handleResponse(response, request.expects)
    } else {
        throw new RequestError(response)
    }
}

const post = async (request: PostRequest): Promise<Result> => {
    let body
    let contentType
    if (request.data instanceof FormData) {
        body = request.data
    } else if (typeof request.data === 'object') {
        body = JSON.stringify(request.data)
        contentType = 'application/json'
    } else {
        body = request.data
        contentType = 'application/json'
    }
    const response = await fetch(`${ENVIRONMENT.API_BASE_URL}${request.path}${query(request)}`, {
        method: 'POST',
        body,
        headers: {
            ...contentType ? {
                'Content-Type': contentType,
            } : {},
            ...request.headers,
        },
    })
    if (response.status >= 200 && response.status < 300) {
        return handleResponse(response, request.expects)
    } else {
        throw new RequestError(response)
    }
}

const put = async (request: PostRequest): Promise<Result> => {
    const response = await fetch(`${ENVIRONMENT.API_BASE_URL}${request.path}${query(request)}`, {
        method: 'PUT',
        body: JSON.stringify(request.data),
        headers: {
            'Content-Type': 'application/json',
            ...request.headers,
        },
    })
    if (response.status >= 200 && response.status < 300) {
        return handleResponse(response, request.expects)
    } else {
        throw new RequestError(response)
    }
}

const patch = async (request: PatchRequest): Promise<Result> => {
    const response = await fetch(`${ENVIRONMENT.API_BASE_URL}${request.path}${query(request)}`, {
        method: 'PATCH',
        body: JSON.stringify(request.data),
        headers: {
            'Content-Type': 'application/json',
            ...request.headers,
        },
    })
    if (response.status >= 200 && response.status < 300) {
        return handleResponse(response, request.expects)
    } else {
        throw new RequestError(response)
    }
}

const dDelete = async (request: PostRequest): Promise<Result> => {
    const response = await fetch(`${ENVIRONMENT.API_BASE_URL}${request.path}${query(request)}`, {
        method: 'DELETE',
        body: JSON.stringify(request.data),
        headers: {
            'Content-Type': 'application/json',
            ...request.headers,
        },
    })
    if (response.status >= 200 && response.status < 300) {
        return handleResponse(response, request.expects)
    } else {
        throw new RequestError(response)
    }
}

const defaultContext: Connection = {
    get,
    post,
    put,
    patch,
    delete: dDelete,
}

export const ConnectionContext = React.createContext(defaultContext)

export const ConnectionConsumer = ConnectionContext.Consumer

export class ConnectionProvider extends React.Component<Props> {

    public get(request: GetRequest) {
        return get((this.addAuthHeader(request) as GetRequest))
    }

    public post(request: PostRequest) {
        return post((this.addAuthHeader(request) as PostRequest))
    }

    public put(request: PostRequest) {
        return put((this.addAuthHeader(request) as PostRequest))
    }

    public patch(request: PatchRequest) {
        return patch((this.addAuthHeader(request) as PatchRequest))
    }

    public delete(request: GetRequest) {
        return dDelete((this.addAuthHeader(request) as GetRequest))
    }

    public render() {
        const connectionProviderValue = {
            get: (request: GetRequest) => this.get(request),
            post: (request: PostRequest) => this.post(request),
            put: (request: PostRequest) => this.put(request),
            patch: (request: PatchRequest) => this.patch(request),
            delete: (request: GetRequest) => this.delete(request),
        }
        return (
            <ConnectionContext.Provider value={ connectionProviderValue }>
                { this.props.children }
            </ConnectionContext.Provider>
        )
    }

    private addAuthHeader = (request: Request): Request => {
        if (this.props.accessToken !== undefined) {
            Object.assign(request, {
                headers: {
                    ...request.headers,
                    'Authorization': `Bearer ${this.props.accessToken}`,
                    'X-ID-Token': `${this.props.idToken}`,
                },
            })
        }
        return request
    }
}
