export interface Props {
    children: React.ReactNode,
    accessToken?: string,
    idToken?: string,
}

export enum ResponseFormat {
    'JSON' = 'JSON',
    'TEXT' = 'TEXT',
    'BLOB' = 'BLOB',
    'NONE' = 'NONE',
}

export interface Request {
    path: string,
    query?: {
        [key: string]: string | number | object | undefined,
    },
    headers?: {
        [key: string]: string,
    },
    expects?: ResponseFormat,
}

// tslint:disable-next-line:no-empty-interface
export interface GetRequest extends Request {}

export interface PostRequest extends Request {
    data?: {
        [key: string]: string | number | object,
    } | unknown[] | string | FormData
}

export interface PatchRequest extends Request {
    data?: {
        [key: string]: string | number | object | boolean,
    } | unknown[] | string | FormData
}

export interface Result {
    statusCode: number,
    data?: string | number | object,
    error?: string | object,
}

export interface Connection {
    get: (request: GetRequest) => Promise<Result>,
    post: (request: PostRequest) => Promise<Result>,
    put: (request: PostRequest) => Promise<Result>,
    delete: (request: PostRequest) => Promise<Result>,
    patch: (request: PatchRequest) => Promise<Result>,
}
