export type LocalStorageKey = string
interface LocalStorageKeys {
    [key: string]: LocalStorageKey,
}

export const LOGIN_STATE: LocalStorageKey = 'csrf_token'
export const ITEMS_PER_PAGE: LocalStorageKey = 'items_per_page'
export const OVERVIEW_FILTERS: LocalStorageKey = 'overview_filters'
export const OVERVIEW_SHOW_COLUMNS: LocalStorageKey = 'overview_show_columns'
export const QUEUE_STATUS_SHOW_TECH: LocalStorageKey = 'queue_status_show_tech'

export const LOCAL_STORAGE_KEYS: LocalStorageKeys = {
    LOGIN_STATE,
    ITEMS_PER_PAGE,
    OVERVIEW_FILTERS,
    OVERVIEW_SHOW_COLUMNS,
    QUEUE_STATUS_SHOW_TECH,
}
