import { css } from 'styled-components'
import { FormGroupProps, FormGroupOptionalRequiredTagElement, WithThemeProp } from '@tblg/components'
import { MixinHasError } from './mixins/HasError'
import { MixinInput } from './mixins/Input'

export const FormGroup = (props: FormGroupProps & WithThemeProp) => css`
    margin-bottom: 2em;
    
    ${ props.disabled && css`
        > label {
            color: ${ props.theme.colors.gray2 };
        }
    ` }
    
    ${/* sc-sel */FormGroupOptionalRequiredTagElement} {
        color: ${ props.theme.colors.tertiary };
        display: inline-block;
        font-size: 0.85rem;
        margin-bottom: 0.5rem;
    }
    
    /* stylelint-disable */
    /* Mixins */
    ${ MixinInput(props) }
    ${ MixinHasError(props) }
`
