import { css } from 'styled-components'
import {
    InputGroupProps,
    InputGroupInputAppendElement,
    InputGroupInputPrependElement,
    WithThemeProp,
} from '@tblg/components'

export const InputGroup = (props: InputGroupProps & WithThemeProp) => css`
    display: flex;
    
    ${ InputGroupInputAppendElement },  ${ InputGroupInputPrependElement } {
      align-items: center;
      background-color: ${props.theme.colors.gray1};
      display: flex;
      padding: 0.65em 1em;
      transition: background-color 0.15s;
    }
    
    ${ InputGroupInputPrependElement } {
        border-top-left-radius: 0.25em;
        border-bottom-left-radius: 0.25em;
    }
    
    ${ InputGroupInputAppendElement } {
        border-top-right-radius: 0.25em;
        border-bottom-right-radius: 0.25em;
    }
    
    &:focus-within {
      ${ InputGroupInputAppendElement },  ${ InputGroupInputPrependElement } {
        background-color: ${props.theme.colors.quaternary};
        color: ${props.theme.colors.white};
      }
    }
    
    ${ props.prepend && css`
      input, select, textarea {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    ` }
    
    ${ props.append && css`
      input, select, textarea {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    ` }
   
`
