import { IconCollection as IconCollectionType } from '@tblg/components'

export const IconCollection: IconCollectionType = {
    fontFamily: 'Icomoon',

    icons: {
        alarm: '\\e9a9',
        apartment: '\\e9a8',
        arrowDown: '\\e9a6',
        arrowDownCircle: '\\e9a7',
        arrowLeft: '\\e9a4',
        arrowLeftCircle: '\\e9a5',
        arrowRight: '\\e9a2',
        arrowRightCircle: '\\e9a3',
        arrowUp: '\\e9a0',
        arrowUpCircle: '\\e9a1',
        bicycle: '\\e99f',
        bold: '\\e99e',
        book: '\\e99d',
        bookmark: '\\e99c',
        briefcase: '\\e99b',
        bubble: '\\e99a',
        bug: '\\e999',
        bullhorn: '\\e998',
        bus: '\\e997',
        calendarFull: '\\e996',
        camera: '\\e994',
        cameraVideo: '\\e995',
        car: '\\e9b4',
        cart: '\\e992',
        chartBars: '\\e991',
        checkmarkCircle: '\\e990',
        chevronDown: '\\e98e',
        chevronDownCircle: '\\e98f',
        chevronLeft: '\\e98c',
        chevronLeftCircle: '\\e98d',
        chevronRight: '\\e98a',
        chevronRightCircle: '\\e98b',
        chevronUp: '\\e988',
        chevronUpCircle: '\\e989',
        circleMinus: '\\e987',
        clock: '\\e986',
        cloud: '\\e981',
        cloudCheck: '\\e985',
        cloudDownload: '\\e984',
        cloudSync: '\\e983',
        cloudUpload: '\\e982',
        code: '\\e980',
        coffeeCup: '\\e97f',
        cog: '\\e97e',
        construction: '\\e97d',
        crop: '\\e97c',
        cross: '\\e97a',
        crossCircle: '\\e97b',
        database: '\\e979',
        diamond: '\\e978',
        dice: '\\e977',
        dinner: '\\e976',
        directionLtr: '\\e975',
        directionRtl: '\\e974',
        download: '\\e973',
        drop: '\\e972',
        earth: '\\e971',
        enter: '\\e96f',
        enterDown: '\\e970',
        envelope: '\\e96e',
        exit: '\\e96c',
        exitUp: '\\e96d',
        eye: '\\e96b',
        fileAdd: '\\e96a',
        fileEmpty: '\\e969',
        filmPlay: '\\e968',
        flag: '\\e967',
        frameContract: '\\e966',
        frameExpand: '\\e965',
        funnel: '\\e964',
        gift: '\\e963',
        graduationHat: '\\e962',
        hand: '\\e961',
        heart: '\\e95f',
        heartPulse: '\\e960',
        highlight: '\\e95e',
        history: '\\e95d',
        home: '\\e95c',
        hourglass: '\\e95b',
        inbox: '\\e95a',
        indentDecrease: '\\e959',
        indentIncrease: '\\e958',
        italic: '\\e957',
        keyboard: '\\e956',
        laptop: '\\e954',
        laptopPhone: '\\e955',
        layers: '\\e953',
        leaf: '\\e952',
        license: '\\e951',
        lighter: '\\e950',
        lineSpacing: '\\e94f',
        linearicons: '\\e94e',
        link: '\\e94d',
        list: '\\e94c',
        location: '\\e94b',
        lock: '\\e94a',
        magicWand: '\\e949',
        magnifier: '\\e948',
        map: '\\e946',
        mapMarker: '\\e947',
        menu: '\\e944',
        menuCircle: '\\e945',
        mic: '\\e943',
        moon: '\\e942',
        move: '\\e941',
        musicNote: '\\e940',
        mustache: '\\e93f',
        neutral: '\\e93e',
        pageBreak: '\\e93d',
        paperclip: '\\e93c',
        paw: '\\e93b',
        pencil: '\\e93a',
        phone: '\\e938',
        phoneHandset: '\\e939',
        picture: '\\e937',
        pieChart: '\\e936',
        pilcrow: '\\e935',
        plusCircle: '\\e934',
        pointerDown: '\\e933',
        pointerLeft: '\\e932',
        pointerRight: '\\e931',
        pointerUp: '\\e930',
        poop: '\\e92f',
        powerSwitch: '\\e92e',
        printer: '\\e92d',
        pushpin: '\\e92c',
        questionCircle: '\\e92b',
        redo: '\\e92a',
        rocket: '\\e929',
        sad: '\\e928',
        screen: '\\e927',
        select: '\\e926',
        shirt: '\\e925',
        smartphone: '\\e924',
        smile: '\\e923',
        sortAlphaAsc: '\\e922',
        sortAmountAsc: '\\e921',
        spellCheck: '\\e920',
        star: '\\e91d',
        starEmpty: '\\e91f',
        starHalf: '\\e91e',
        store: '\\e91c',
        strikethrough: '\\e91b',
        sun: '\\e91a',
        sync: '\\e919',
        tablet: '\\e918',
        tag: '\\e917',
        textAlignCenter: '\\e916',
        textAlignJustify: '\\e915',
        textAlignLeft: '\\e914',
        textAlignRight: '\\e913',
        textFormat: '\\e911',
        textFormatRemove: '\\e912',
        textSize: '\\e910',
        thumbsDown: '\\e90f',
        thumbsUp: '\\e90e',
        train: '\\e90d',
        trash: '\\e90c',
        underline: '\\e90b',
        undo: '\\e90a',
        unlink: '\\e909',
        upload: '\\e908',
        user: '\\e907',
        users: '\\e906',
        volume: '\\e902',
        volumeHigh: '\\e905',
        volumeLow: '\\e904',
        volumeMedium: '\\e903',
        warning: '\\e901',
        wheelchair: '\\e900',
        tire: '\\e9b0',
        dataStorage: '\\e9be',
        server: '\\e9bf',
        files: '\\e9c0',
        databases: '\\e9c1',
        input: '\\e9c2',
        fuelTank: '\\e9c3',
        fuel: '\\e9c4',
        oil: '\\e9c7',
        traffic: '\\e9c8',
        growth: '\\e9c9',
        moneyGrowth: '\\e9ca',
        moneyIncrease: '\\e9cb',
        valueIncrease: '\\e9cc',
        discountTag: '\\e9cd',
        residualValue: '\\e993  ',
        discount: '\\e9cf',
        chevronFatLeftDouble: '\\e9aa',
        chevronFatRightDouble: '\\e9ab',
        chevronFatDown: '\\e9ac',
        chevronFatLeft: '\\e9ad',
        chevronFatRight: '\\e9ae',
        chevronFatUp: '\\e9af',
        autotelex: '\\e9b1',
        jato: '\\e9b2',
        insurance: '\\e9b6',
        creditCard: '\\e9b3',
        calculator: '\\e9b7',
        saveDisk: '\\e9b5',
    },
}

export default IconCollection
