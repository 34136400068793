import { TableColumnProps, TableState, WithThemeProp, TableSortDirection } from '@tblg/components'
import { css } from 'styled-components'

export const TableHeaderCell = (props: TableColumnProps & TableState & WithThemeProp) => css`
  border-bottom: 1px solid ${ props.theme.colors.gray1 };
  color: ${ props.theme.colors.text };
  font-weight: 500;
  padding: 1rem;
  position: relative;
  text-align: left;
  vertical-align: bottom;
  white-space: nowrap;

  ${ props.sortable && css`
    cursor: pointer;

    ${ props.sortBy === props.identifier && css`
      color: ${ props.theme.colors.quaternary };
      &:after {
        content: '${ props.theme.iconCollection.icons.chevronFatUp }';
        display: inline-block;
        font-family: ${ props.theme.iconCollection.fontFamily };
        font-size: 0.65rem;
        margin-left: 1rem;
        transition: transform 0.2s ease-in-out;

        ${ props.sortDirection === TableSortDirection.desc && css`
          transform: rotate(180deg);
        ` }
      }
    ` }
  `}
`
