// Interfaces
import { Theme as ThemeInterface } from '@tblg/components'

// Utilities
import { ColorPalette } from './utilities/ColorPalette'
import { IconCollection } from './utilities/IconCollection'
import { Breakpoints } from './utilities/Breakpoints'

// Components > atoms
import { Alert } from './components/atoms/Alert'
import { Avatar } from './components/atoms/Avatar'
import { Button } from './components/atoms/Button'
import { DropdownOptionList, DropdownOptionListItem } from './components/atoms/DropdownOptionList'
import { Heading } from './components/atoms/Heading'
import { Input } from './components/atoms/Input'
import { Label } from './components/atoms/Label'
import { Link } from './components/atoms/Link'
import { NumberInput } from './components/atoms/NumberInput'
import { Select } from './components/atoms/Select'
import { Panel } from './components/atoms/Panel'
import { Spinner } from './components/atoms/Spinner'
import { SubtitleGroup } from './components/atoms/SubtitleGroup'
import { TextArea } from './components/atoms/TextArea'

// Components > molecules
import { Autocomplete } from './components/molecules/Autocomplete'
import { CheckboxGroup } from './components/molecules/CheckboxGroup'
import { CheckboxOption } from './components/molecules/CheckboxOption'
import { DatePicker } from './components/molecules/DatePicker'
import { FormGroup } from './components/molecules/FormGroup'
import { InputGroup } from './components/molecules/InputGroup'
import { ListTree } from './components/molecules/ListTree'
import { Loader } from './components/molecules/Loader'
import { Pagination } from './components/molecules/Pagination'
import { PaginationButton } from './components/molecules/Pagination/Button'
import { Profile } from './components/molecules/Profile'
import { RadioButtonOption } from './components/molecules/RadioButtonOption'
import { Table } from './components/molecules/Table'
import { TableCell } from './components/molecules/Table/Cell'
import { TableHeaderCell } from './components/molecules/Table/HeaderCell'
import { TableRow } from './components/molecules/Table/Row'
import { Tab, Tabs } from './components/molecules/Tabs'

// Components > organisms
import { Menu } from './components/organisms/Menu'
import { MenuGroupTitle } from './components/organisms/Menu/GroupTitle'
import { MenuItem } from './components/organisms/Menu/Item'

export const Theme: ThemeInterface = {
    colors: ColorPalette,
    iconCollection: IconCollection,
    breakpoints: Breakpoints,

    // atoms
    alert: Alert,
    avatar: Avatar,
    button: Button,
    dropdownOptionList: DropdownOptionList,
    dropdownOptionListItem: DropdownOptionListItem,
    heading: Heading,
    input: Input,
    label: Label,
    numberInput: NumberInput,
    select: Select,
    panel: Panel,
    spinner: Spinner,
    subtitleGroup: SubtitleGroup,
    textArea: TextArea,

    // molecules
    autocomplete: Autocomplete,
    checkboxGroup: CheckboxGroup,
    checkboxOption: CheckboxOption,
    datePicker: DatePicker,
    formGroup: FormGroup,
    inputGroup: InputGroup,
    loader: Loader,
    link: Link,
    listTree: ListTree,
    pagination: Pagination,
    paginationButton: PaginationButton,
    profile: Profile,
    radioButtonOption: RadioButtonOption,
    tab: Tab,
    table: Table,
    tableCell: TableCell,
    tableHeaderCell: TableHeaderCell,
    tableRow: TableRow,
    tabs: Tabs,

    // organisms
    menu: Menu,
    menuGroupTitle: MenuGroupTitle,
    menuItem: MenuItem,
}
