import { HeadingContentType, HeadingState, WithThemeProp } from '@tblg/components'
import { css, FlattenSimpleInterpolation } from 'styled-components'

export const MixinContentType = (props: HeadingState & WithThemeProp): FlattenSimpleInterpolation => {
    switch (props.contentType) {
        case HeadingContentType.iconLeft:
            return css`
                i {
                    margin-right: 0.5rem;
                }
            `
        case HeadingContentType.iconRight:
            return css`
                  i {
                    margin-left: 0.5rem;
                }
            `

        default:
            return css``
    }
}
