import { css, FlattenSimpleInterpolation } from 'styled-components'
import { CheckboxProps, WithThemeProp, FormGroupErrorMessageElement } from '@tblg/components'

export const MixinHasError = ({ hasError, theme }: CheckboxProps &  WithThemeProp): FlattenSimpleInterpolation => css`
    ${ hasError && `
        > label {
            color: ${ theme.colors.error };
        }
    `}
    ${/* sc-sel */FormGroupErrorMessageElement} {
        color: ${ theme.colors.error };
    }
`
