import { css } from 'styled-components'
import { LabelElement } from '@tblg/components'

export const CheckboxGroup = () => css`
    ${ LabelElement } {
      &:last-child {
        margin-bottom: 0;
      }
    }
`
