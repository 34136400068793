import { css } from 'styled-components'
import { HeadingProps, WithThemeProp, HeadingState } from '@tblg/components'
import { MixinType } from './mixins/Type'
import { MixinContentType } from './mixins/ContentType'

export const Heading = (props: HeadingProps & HeadingState & WithThemeProp) => css`
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0.5rem;
    margin-top: 0;

    /* stylelint-disable */
    /* Mixins */
    ${ MixinType(props) }
    ${ MixinContentType(props) }
`
