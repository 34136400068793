import { css, FlattenSimpleInterpolation } from 'styled-components'
import { AvatarProps, WithThemeProp } from '@tblg/components'

export const MixinColor = ({ initials = 'VB', theme }: AvatarProps & WithThemeProp): FlattenSimpleInterpolation => {

    switch (parseInt(initials, 36) % 4) {

        case 0:
            return css`
                background-color: ${ theme.colors.primary };
            `

        case 1:
            return css`
                background-color: ${ theme.colors.success };
            `

        case 2:
            return css`
                background-color: ${ theme.colors.error };
            `

        case 3:
        default:
            return css`
                background-color: ${ theme.colors.warning };
            `
    }

}
