import styled from 'styled-components'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()
const appEnv = (publicRuntimeConfig?.APP_ENV || 'NL-PROD').split('-').pop()

const backgroundGradients: Record<string, { from: string, to: string }> = {
    LOCAL: { from: '#72d6fa', to: '#5579eb' },
    TEST: { from: '#1dc9b7', to: '#32b880' },
    ACC: { from: '#1dc9b7', to: '#32b880' },
    STAGING: { from: '#1dc9b7', to: '#32b880' },
    PROD: { from: '#26a0be', to: '#32b880' },
}
const gradient = backgroundGradients[appEnv]

export const BackgroundElement = styled.div`
    align-items: center;
    background-image: url('/images/bg/banner-tr-${ appEnv }.png'),
    linear-gradient(to bottom left, ${ gradient.from }, ${ gradient.to });
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    background-color: #B8DFE7;
    display: flex;
    height: 100%;
    position: fixed;
    width: 100%;
`

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`
