import { css } from 'styled-components'
import { CheckboxOptionProps, WithThemeProp } from '@tblg/components'

export const CheckboxOption = (props: CheckboxOptionProps & WithThemeProp) => css`
    align-items: flex-start;
    cursor: pointer;
    display: flex;
    margin-bottom: 0.5rem;
  
    > div {
        margin-right: 1rem;
    }
    
    ${props.disabled && css`
        color: ${ props.theme.colors.gray2 };
    `}
`
