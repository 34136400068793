import { css } from 'styled-components'
import { TextAreaProps, WithThemeProp } from '@tblg/components'
import { MixinHasError } from './mixins/HasError'

export const TextArea = (props: TextAreaProps & WithThemeProp) => css`
    background-color: ${props.theme.colors.white};
    border: 1px solid ${props.theme.colors.gray1};
    border-radius: 0.25em;
    box-sizing: border-box;
    color: ${props.theme.colors.black};
    display: block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.65em 1em;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    width: 100%;
    
    &:focus {
        border-color: ${props.theme.colors.quaternary};
        outline: 0;
    }
    
    &:disabled {
        background-color: ${props.theme.colors.gray0};
    }
    
    /* stylelint-disable */
    /* Mixins */
    ${ MixinHasError(props) }
`
