import { css } from 'styled-components'
import { WithThemeProp, AvatarProps } from '@tblg/components'
import { MixinColor } from './mixins/Color'

export const Avatar = (props: AvatarProps & WithThemeProp) => css`
    margin: 0;
    > img,
    > span {
        border-radius: 50%;
        display: inline-block;
        font-size: 1.1rem;
        height: 3em;
        line-height: 3em;
        text-align: center;
        width: 3em;

        /* stylelint-disable */
        /* Mixins */
        ${ MixinColor(props) }
    }
    > img {
        vertical-align: middle;
    }
    > span {
        color: ${ props.theme.colors.white };
        font-weight: 500;
        text-transform: uppercase;
    }

`
