import { transparentize } from 'polished'
import { css, FlattenSimpleInterpolation } from 'styled-components'
import { TextAreaProps, WithThemeProp } from '@tblg/components'

export const MixinHasError = ({ hasError, theme }: TextAreaProps &  WithThemeProp): FlattenSimpleInterpolation => css`
    ${ hasError && `
        &:not(:focus) {
            border-color: ${ theme.colors.error };
            background-color: ${ transparentize(0.95, theme.colors.error) };
        }
    `}
`
