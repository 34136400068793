import { ColorPalette as ColorPaletteType } from '@tblg/components'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()
const appEnv = (publicRuntimeConfig?.APP_ENV || 'NL-PROD').split('-').pop()
const environmentColors: Record<string, string> = {
    LOCAL: '#4B6DE8',
    TEST: '#1BC2AE',
    ACC: '#FFB12D',
    STAGING: '#FFB12D',
    PROD: '#fd397a',
}

const primary = environmentColors[appEnv] || '#fd397a'
const secondary = '#282733'
const tertiary = '#6C7293'
const quaternary = '#5578EB'
const quinary = '#2c77f4'

const dark = '#282A3C'
const light = '#FFF'

const gray0 = '#F0F3FF'
const gray1 = '#e2e5ec'
const gray2 = '#bfc7d7'
const gray3 = '#a7abc3'
const gray4 = '#99A6BF'
const gray5 = '#F6F7F9'
const gray6 = '#74788d'
const gray7 = '#595d6e'

export const ColorPalette: ColorPaletteType = {
    // brand colors
    primary,
    secondary,
    tertiary,
    quaternary,
    quinary,

    // gray scale colors
    black: dark,
    white: light,
    gray: '#f2f3f8',
    gray0,
    gray1,
    gray2,
    gray3,
    gray4,
    gray5,
    gray6,
    gray7,

    lightBlue: '#EAF1FF',
    text: '#646C9A',

    // status colors
    error: primary,
    info: quaternary,
    success: '#0ABB87',
    warning: '#FFB822',

    // text colors
    textNormal: dark,
    textSubtle: tertiary,
    textLight: gray3,

    // button specific colors
    buttonPrimary: quaternary,
    buttonSecondary: tertiary,
    buttonLight: light,
    buttonDark: dark,

    // side bar menu specific colors
    sideBarMenuTopBar: '#201F2B',
    sideBarMenuHamburger: '#484f66',
    sideBarMenuHamburgerHover: light,
    sideBarMenuBackground: '#282733',
    sideBarMenuBackgroundHover: '#2f2e3c',
    sideBarMenuBackgroundSelected: primary,
    sideBarMenuIcon: '#6868a1',
    sideBarMenuIconHover: quinary,
    sideBarMenuIconSelected: light,
    sideBarMenuText: '#9899ac',
    sideBarMenuTextHover: light,
    sideBarMenuTextSelected: light,
    sideBarMenuExpandButton: '#7e7eae',
    sideBarMenuExpandButtonHover: light,
    sideBarMenuExpandButtonSelected: light,
    sideBarMenuTitle: '#515276',
    sideBarScrollbar: gray3,

    // top menu specific colors
    topMenuBorders: '#eff0f6',
    topMenuButtonText: '#5f6281',
    topMenuButtonTextHover: quaternary,
    topMenuButtonBackgroundHover: '#f7f8fd',
    topMenuButtonLightText: '#959cb6',

    // user menu specific colors
    userMenuBorders: '#f7f8fa',
    userMenuBackgroundHover: gray5,

    // pagination specific colors
    numberColor: '#93a2dd',
    highlightColor: '#5d78ff',

    // datepicker specific colors
    datePickerFont: gray7,
    datePickerBackground: '#f7f8fa',
    datePickerToday: '#99aaff',
    datePickerSelect: '#003399',
    datePickerFontLight: '#989db5',
    datePickerHeading: gray7,
}
