import { css, FlattenSimpleInterpolation } from 'styled-components'
import { FormGroupProps, WithThemeProp, FormGroupErrorMessageElement } from '@tblg/components'

export const MixinInput = ({ input }: FormGroupProps &  WithThemeProp): FlattenSimpleInterpolation => css`
    ${ (input.name === 'Checkbox' || input.name === 'Switch') && `
        display: flex;
        align-items: center;
        
        label:first-child {
            order: 1;
            margin-bottom: 0;
            margin-left: 0.5rem;
            cursor: pointer;
        }
        ${/* sc-sel */FormGroupErrorMessageElement} {
            order: 2;
            width: 100%;
        }
    `}
`
