interface Breakpoints {
  sm: string,
  md: string,
  lg: string,
  xl: string,
}

export const Breakpoints: Breakpoints = {
  sm: '576px',
  md: '768px',
  lg: '1024px',
  xl: '1300px',
}
