import { AlertState, AlertProps, WithThemeProp } from '@tblg/components'
import { css, FlattenSimpleInterpolation } from 'styled-components'

export const MixinState = ({ state, theme }: AlertProps & WithThemeProp): FlattenSimpleInterpolation => {
    switch (state) {
        case AlertState.success:
            return css`
                background-color: ${ theme.colors.success };
                color: ${ theme.colors.white };
            `

        case AlertState.warning:
            return css`
                background-color: ${ theme.colors.warning };
                color: ${ theme.colors.black };
            `

        case AlertState.error:
            return css`
                background-color: ${ theme.colors.error };
                color: ${ theme.colors.white };
            `
        case AlertState.info:
        default:
            return css`
                background-color: ${ theme.colors.info };
                color: ${ theme.colors.white };
            `
    }
}
