import { css, FlattenSimpleInterpolation } from 'styled-components'
import { HeadingType, HeadingProps, WithThemeProp } from '@tblg/components'

export const MixinType = ({ type }: HeadingProps & WithThemeProp): FlattenSimpleInterpolation => {

    switch (type) {

        case HeadingType.h1:
            return css`
                font-size: 2.5rem;
            `

        case HeadingType.h2:
            return css`
                font-size: 2rem;
            `

        case HeadingType.h3:
            return css`
                font-size: 1.75rem;
            `

        case HeadingType.h4:
            return css`
                font-size: 1.5rem;
            `

        case HeadingType.h5:
            return css`
                font-size: 1.25rem;
            `

        case HeadingType.h6:
        default:
            return css`
                font-size: 1rem;
            `

    }

}
