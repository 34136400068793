import { css } from 'styled-components'
import { TableCellElement, WithThemeProp } from '@tblg/components'

export const TableRow = (props: WithThemeProp) => css`
  ${/* sc-sel */TableCellElement} {
    border-bottom: 1px solid ${ props.theme.colors.gray1 };
  }
  
  &:last-child {
    ${/* sc-sel */TableCellElement} {
      border-bottom: none;
    }
  }
`
