import { WithThemeProp } from '@tblg/components'
import { css } from 'styled-components'

export const SubtitleGroup = (props: WithThemeProp) => css`
    line-height: 0;
    padding-left: 0.5em;

    p, small {
        line-height: 1.5em;
    }
    p {
        color: ${ props.theme.colors.secondary };
        font-size: 1rem;
        font-weight: 500;
        margin: 0;
    }
    small {
        color: ${ props.theme.colors.gray3 };
        font-size: 0.9rem;
    }
`
