import { css } from 'styled-components'
import {
    WithThemeProp,
    DatePickerProps,
    DatePickerItemElement,
    DatePickerItemMonthElement,
    DatePickerItemTodayElement,
    DatePickerItemSelectedElement,
    DatePickerItemOutsideElement,
    DatePickerCalendarHeadElement,
    DatePickerCalendarElement,
} from '@tblg/components'
import { MixinHasError } from './mixins/HasError'

export const DatePicker = (props: DatePickerProps & WithThemeProp) => css`
     ${/* sc-sel */DatePickerCalendarElement} {
        align-items: center;
        background-color: ${props.theme.colors.white};
        border-radius: 4px;
        box-shadow: 4px 0 30px 0 rgba(82, 63, 105, 0.15);
        display: flex;
        justify-content: center;
        padding: 1rem;
        width: 20rem;
        z-index: 2;
    
        ${/* sc-sel */DatePickerItemElement} {
            background-color: transparent;
            border-radius: 0.25rem;
            color: ${props.theme.colors.datePickerFont};
            cursor: pointer;
            font-weight: 400;
            height: 2.3rem;
            padding: 0.25rem;
            text-align: center;
            width: 2.3rem;
    
            &:hover {
                background-color: ${props.theme.colors.datePickerBackground};
            }
        }
    
        ${/* sc-sel */DatePickerItemTodayElement} {
            background-color: ${props.theme.colors.datePickerToday};
            color: white;
            position: relative;
    
            &::after {
                border: solid transparent;
                border-bottom-color: ${props.theme.colors.white};
                border-top-color: ${props.theme.colors.white};
                border-width: 0 0 7px 7px;
                bottom: 4px;
                content: '';
                display: inline-block;
                position: absolute;
                right: 4px;
            }
        }
    
        ${/* sc-sel */DatePickerItemMonthElement} {
            height: 4.8rem;
            width: 4.8rem;
        }
    
        ${/* sc-sel */DatePickerItemSelectedElement} {
            background-color: ${props.theme.colors.datePickerSelect};
            color: ${props.theme.colors.white};
        }
    
        ${/* sc-sel */DatePickerItemOutsideElement} {
            color: ${props.theme.colors.datePickerFontLight};
        }
    
        ${/* sc-sel */DatePickerCalendarHeadElement} {
            color: ${props.theme.colors.datePickerHeading};
            font-weight: 500;
            height: 2.3rem;
        }
    }

    /* stylelint-disable */
    /* Mixins */
    ${
        MixinHasError(props)
    }
`
