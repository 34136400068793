/* stylelint-disable */
import { MenuGroupTitleProps, MenuGroupTitleTextElement, WithThemeProp } from '@tblg/components'
import { css } from 'styled-components'

export const MenuGroupTitle = (props: MenuGroupTitleProps & WithThemeProp) => css`
    padding: 2rem 2rem 1rem 2rem;
    position: relative;
    white-space: nowrap;
    width: 100%;
    
    ${/* sc-sel */MenuGroupTitleTextElement} {
        color: ${ props.theme.colors.sideBarMenuTitle };
        display: inline-block;
        font-size: 0.9rem;
        font-weight: 500;
        letter-spacing: 0.3px;
        margin: 0;
        text-transform: uppercase;
    }
    
    ${props.minimized && css`
         ${/* sc-sel */MenuGroupTitleTextElement} {
            visibility: hidden;
         }
         
         &::before {
            color: ${ props.theme.colors.sideBarMenuTitle };
            content: '...';
            font-size: 2.5rem;
            position: absolute;
            top: 0;
         }
    `}
`
