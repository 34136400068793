/* stylelint-disable */
import {
    MenuContext,
    MenuItemProps,
    MenuItemExpandButtonElement,
    MenuItemIconElement,
    MenuItemTextElement,
    MenuItemTitleElement,
    MenuItemSubtitleElement,
    MenuItemSVGContainerElement,
    WithThemeProp,
} from '@tblg/components'
import { css } from 'styled-components'

const subItemSelected = (item: MenuItemProps): boolean => {
    return Array.isArray(item.subItems) &&
        item.subItems.find(subItem => subItem.selected || subItemSelected(subItem)) !== undefined
}

const MainItem = (props: MenuItemProps & WithThemeProp) => css`
    padding: 1rem 1.75rem;

    ${/* sc-sel */MenuItemIconElement} {
        color: ${ props.theme.colors.sideBarMenuIcon };
        font-size: 1.5rem;
        height: 1.5rem;
        margin-right: 1.75rem;
        position: relative;
        width: 1.5rem;
    }

     ${/* sc-sel */MenuItemSVGContainerElement} {
        height: 1.5rem;
        margin-right: 1.75rem;
        position: relative;
        width: 1.5rem;

        svg {
          height: 100%;
          width: 100%;
        }
    }

    ${/* sc-sel */MenuItemTextElement} {
        color: ${ props.theme.colors.sideBarMenuText };
        flex-grow: 1;
        font-weight: 400;
        opacity: 1;
        transition: opacity 0.4s ease;
    }

    ${/* sc-sel */MenuItemExpandButtonElement} {
        color: ${ props.theme.colors.sideBarMenuExpandButton };
        flex-shrink: 0;
        height: 1.4rem;
        text-align: center;
        ${ !props.collapsed && css`
            transform: rotate(90deg);
        ` }
        opacity: 1;
        width: 1.4rem;

        &::after {
            content: '${ props.theme.iconCollection.icons.chevronFatRight }';
            font-size: 0.6rem;
        }
    }

    ${ !props.selected && css`
      &:hover {
        background-color: ${ props.theme.colors.sideBarMenuBackgroundHover };

        ${/* sc-sel */MenuItemTextElement} {
            color: ${ props.theme.colors.sideBarMenuTextHover };
        }

        ${/* sc-sel */MenuItemIconElement} {
          color: ${ props.theme.colors.sideBarMenuIconHover };
        }

        ${/* sc-sel */MenuItemExpandButtonElement} {
          color: ${ props.theme.colors.sideBarMenuExpandButtonHover };
        }
      }
  ` }

  ${ !props.collapsed && css`
    background-color: ${ props.theme.colors.sideBarMenuBackgroundHover };

    ${/* sc-sel */MenuItemTextElement} {
        color: ${ props.theme.colors.sideBarMenuTextHover };
    }

    ${/* sc-sel */MenuItemIconElement} {
      color: ${ props.theme.colors.sideBarMenuIconHover };
    }

    ${/* sc-sel */MenuItemExpandButtonElement} {
      color: ${ props.theme.colors.sideBarMenuExpandButtonHover };
    }
  ` }

  ${ (props.selected || subItemSelected(props)) && css`
    background-color: ${ props.theme.colors.sideBarMenuBackgroundSelected };

    &::after {
      border-style: solid;
      border-width: 1.75rem 0.6rem 1.75rem 0;
      border-color: transparent #f2f3f8 transparent transparent;
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
    }

    ${/* sc-sel */MenuItemTextElement} {
        color: ${ props.theme.colors.sideBarMenuTextSelected };
    }

    ${/* sc-sel */MenuItemIconElement} {
      color: ${ props.theme.colors.sideBarMenuIconSelected };
    }

    ${/* sc-sel */MenuItemExpandButtonElement} {
      color: ${ props.theme.colors.sideBarMenuExpandButtonSelected };
    }
  ` }
`

const SubItem = (props: MenuItemProps & WithThemeProp) => css`
    color: ${ props.theme.colors.sideBarMenuText };
    padding: 0.75rem 0.5rem 0.75rem 4.5rem;

    ${ (props.selected || !props.collapsed) && css`
        background-color: ${ props.theme.colors.sideBarMenuBackgroundHover };
        color: ${ props.theme.colors.sideBarMenuTextHover };
  ` }

    ${ props.minimized && css`
        display: none;
  ` }

    &::before {
        background-color: ${ props.theme.colors.sideBarMenuText };
        border-radius: 50%;
        content: '';
        display: inline-block;
        height: 0.4rem;
        margin-right: 1rem;
        width: 0.4rem;
    }

    &:hover {
        background-color: ${ props.theme.colors.sideBarMenuBackgroundHover };
        color: #fff;

        &::before {
          background-color: ${ props.theme.colors.sideBarMenuIconHover };
        }
    }

`

const MainMenuItem = (props: MenuItemProps & WithThemeProp) => css`
    width: 20rem;

    ${(!props.collapsible || !props.subItems) && css`
         ${/* sc-sel */MenuItemExpandButtonElement} {
             display: none;
         }
    ` }
    ${ props.level === 0 ? MainItem(props) : SubItem(props)}
`

const UserMenuItem = (props: MenuItemProps & WithThemeProp) => css`
    border-bottom: 1px solid ${ props.theme.colors.userMenuBorders };
    padding: 1.1rem 1.5rem;

    &:hover {
        background-color: ${ props.theme.colors.userMenuBackgroundHover };

         ${/* sc-sel */MenuItemTitleElement} {
            color: ${ props.theme.colors.topMenuButtonTextHover };
         }
    }

    ${/* sc-sel */MenuItemIconElement} {
        color: ${ props.theme.colors.tertiary };
        flex: 0 0 2.5rem;
        font-size: 1.4rem;
    }

    ${/* sc-sel */MenuItemSVGContainerElement} {
        height: 1.5rem;
        margin-right: 1.75rem;
        position: relative;
        width: 1.5rem;

        svg {
          height: 100%;
          width: 100%;
        }
    }

    ${/* sc-sel */MenuItemTextElement} {
        align-items: left;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    ${/* sc-sel */MenuItemTitleElement} {
        color: ${ props.theme.colors.textNormal };
        font-weight: 500;
    }

    ${/* sc-sel */MenuItemSubtitleElement} {
        color: ${ props.theme.colors.textLight };
        margin-top: 0.25rem;
    }

    ${/* sc-sel */MenuItemExpandButtonElement} {
        &::after {
            content: '${ props.theme.iconCollection.icons.chevronFatRight }';
            font-size: 0.6rem;
        }
    }
`

export const MenuItem = (props: MenuItemProps & WithThemeProp) => css`
    align-items: center;
    background: none;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    outline: none;
    position: relative;
    text-align: left;
    width: 100%;
    ${ props.context === MenuContext.mainMenu && MainMenuItem(props) }
    ${ props.context === MenuContext.userMenu && UserMenuItem(props) }
`
