/* stylelint-disable */
import { MenuContext, MenuProps, WithThemeProp } from '@tblg/components'
import { css } from 'styled-components'

const MainMenu =  (props: MenuProps & WithThemeProp) => css`
    background-color: #282733;
    
    ${props.minimized && css`
        transition: width 0.4s ease;
    `}
`

const UserMenu = css`
    background-color: #fff;
`

export const Menu = (props: MenuProps & WithThemeProp) => css`
    list-style-type: none;
    margin: 0;
    overflow: hidden;
    padding: 0 0 10rem;

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    ${ props.context === MenuContext.mainMenu && MainMenu(props) }
    ${ props.context === MenuContext.userMenu && UserMenu }
`
