import { ENVIRONMENT } from '@root/environment'
import { Button, Heading, HeadingType, Loader } from '@tblg/components'
import { WebAuth, Auth0DecodedHash } from 'auth0-js'
import { createContext, Component, ReactNode } from 'react'
import { SplashScreen } from '@templates/SplashScreen'
import { Policy } from '@constants/policies'
import Router, { withRouter } from 'next/router'
import { LOGIN_STATE } from '@constants/localStorageKeys'
import { E2E_AUTH_STATE } from '@constants/cookieKeys'
import Cookie from 'js-cookie'
import { WithRouterProps } from 'next/dist/client/with-router'
import moment, { Moment } from 'moment'
import { RequestError } from '@services/Connection/RequestError'

interface Props {
    children: ReactNode,
}

export interface State {
    authenticated: boolean,
    user?: {
        name: string,
        nickname: string,
        picture: string,
    },
    accessToken?: string,
    idToken?: string,
    scope: string[],
    signedOut: boolean,
    expiresAt?: Moment,
}

/**
 * Function types
 */
export type Login = () => void
export type Logout = () => void
export type Can = (policy: Policy, params?: object) => boolean

export interface AuthContextInterface extends State {
    login: Login,
    logout: Logout,
    can: Can,
}

const defaultContext: AuthContextInterface = {
    // tslint:disable-next-line:no-object-literal-type-assertion
    ...({
        authenticated: false,
        user: undefined,
        accessToken: undefined,
        idToken: undefined,
    } as State),
    // tslint:disable-next-line:no-empty
    login: () => {},
    // tslint:disable-next-line:no-empty
    logout: () => {},
    // tslint:disable-next-line:no-empty
    can: () => false,
}

export const AuthContext = createContext(defaultContext)

export const AuthConsumer = AuthContext.Consumer

const initialState: State = {
    authenticated: false,
    user: undefined,
    accessToken: undefined,
    idToken: undefined,
    scope: [],
    signedOut: false,
}

interface LoginStateObject {
    csrfToken: string,
    redirectTo?: {
        pathname: string,
        query: object,
        asPath?: string,
    }
}

class WrappedAuthProvider extends Component<Props & WithRouterProps, State> {
    public state: State = initialState

    private auth0: WebAuth

    constructor(props: Props & WithRouterProps) {
        super(props)

        this.auth0 = new WebAuth({
            audience: ENVIRONMENT.AUTH0.AUDIENCE,
            clientID: `${ENVIRONMENT.AUTH0.CLIENT_ID}`,
            domain: ENVIRONMENT.AUTH0.CUSTOM_DOMAIN,
            redirectUri: ENVIRONMENT.APP_URL,
            responseType: 'token id_token',
        })
    }

    public componentDidCatch(error: Error) {
        if (error instanceof RequestError && error.response.status === 401) {
            this.login()
            return
        }
        if (error instanceof RequestError && error.response.status === 403) {
            this.props.router.push('/403')
            return
        }
        throw error
    }

    public componentDidMount() {
        if (process.browser) {
            const signedOut = window.location.hash === '#signed_out'
            this.setState({
                signedOut,
            })
            if (window.location.hash && window.location.hash.startsWith('#access_token=')) {
                this.handleLogin()
            } else if (Cookie.get(E2E_AUTH_STATE)) {
                // this cookie is used for e2e testing only. never set within app itself.
                this.setState(JSON.parse(Cookie.get(E2E_AUTH_STATE) as string))
            } else {
                localStorage.removeItem(LOGIN_STATE)
                if (!signedOut) {
                    this.login()
                }
            }
        }
    }

    public login: Login = () => {
        const loginState = this.createLoginState()
        localStorage.setItem(LOGIN_STATE, loginState)
        this.auth0.authorize({
            state: loginState,
        })
    }

    public logout: Logout = () => {
        this.setState(initialState)
        localStorage.removeItem(LOGIN_STATE)
        this.auth0.logout({
            returnTo: `${ENVIRONMENT.APP_URL}#signed_out`,
        })
    }

    public can: Can = (policy, params) => policy(this.state, params)

    public render() {
        const authProviderValue = {
            ...this.state,
            login: () => this.login(),
            logout: () => this.logout(),
            can: (policy: Policy, params?: object) => this.can(policy, params),
        }

        if (this.state.authenticated && moment.isMoment(this.state.expiresAt)
            && this.state.expiresAt.isBefore(moment())) {
            // token expires - re-login
            this.login()
        }

        return (
            <AuthContext.Provider value={ authProviderValue }>
                { this.state.authenticated && this.props.children }
                { this.state.signedOut && (
                    <SplashScreen>
                        <Heading type={ HeadingType.h5 }>U bent uitgelogd</Heading>
                        <br/>
                        <Button onClick={ this.login }>Opnieuw inloggen</Button>
                    </SplashScreen>
                ) }
                { !this.state.authenticated && !this.state.signedOut && (
                    <SplashScreen>
                        <Loader />
                    </SplashScreen>
                ) }
            </AuthContext.Provider>
        )
    }

    private parseAuth0Hash(): Promise<Auth0DecodedHash> {
        return new Promise((resolve,reject) => {
            this.auth0.parseHash((error, result) => {
                if (error || result === null) {
                    reject(error)
                } else {
                    resolve(result)
                }
            })
        })
    }

    private handleLogin = async (): Promise<void> => {
        const authResult = await this.parseAuth0Hash()
        const currentUser = await this.getCurrentUser(authResult.accessToken as string, authResult.idToken as string)

        window.location.hash = ''
        const localLoginState = localStorage.getItem(LOGIN_STATE)
        if (localLoginState === undefined || localLoginState === null || localLoginState !== authResult.state) {
            this.logout()
            throw new Error('Non matching login state: preventing possible CSRF attack')
        }
        this.setState({
            authenticated: true,
            accessToken: authResult.accessToken,
            idToken: authResult.idToken,
            user: authResult.idTokenPayload,
            scope: currentUser.permissions || [],
            expiresAt: authResult.expiresIn ? moment().add(authResult.expiresIn, 'seconds') : undefined,
        })
        const localLoginStateObject = this.decodeLoginState(localLoginState)
        let pathname = '/'
        let query = {}
        let asPath
        if (localLoginStateObject.redirectTo) {
            pathname = localLoginStateObject.redirectTo.pathname
            query = localLoginStateObject.redirectTo.query
            asPath = localLoginStateObject.redirectTo.asPath
        }
        Router.push({ pathname, query }, asPath)
    }

    private createLoginState(): string {
        const loginStateObject: LoginStateObject = {
            csrfToken: Math.random().toString(36).substring(2, 15)
                + Math.random().toString(36).substring(2, 15),
            redirectTo: this.props.router ? {
                pathname: this.props.router.pathname,
                query: this.props.router.query,
                asPath: this.props.router.asPath,
            } : undefined,
        }
        return this.encodeLoginState(loginStateObject)
    }

    private encodeLoginState(loginState: LoginStateObject): string {
        return JSON.stringify(loginState)
    }

    private decodeLoginState(loginState: string): LoginStateObject {
        const loginStateObject = JSON.parse(loginState)
        if (!(loginStateObject as LoginStateObject).csrfToken) {
            throw new Error('Invalid login state object')
        }
        return loginStateObject
    }

    private getCurrentUser = async (authToken: string, idToken: string) => {
        try {
            const response = await fetch(`${ENVIRONMENT.API_BASE_URL}/current-user`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                    'X-ID-TOKEN': idToken,
                },
            })

            return await response.json()
        } catch (error) {
            return null
        }
    }
}

export const AuthProvider = withRouter(WrappedAuthProvider)
