import { css } from 'styled-components'
import {
    PaginationButtonProps,
    PaginationButtonLabelElement,
    PaginationButtonType,
    WithThemeProp,
} from '@tblg/components'

export const PaginationButton = (props: PaginationButtonProps & WithThemeProp) => {
    const isLabelButton = props.buttonType === PaginationButtonType.first
        || props.buttonType === PaginationButtonType.previous
        || props.buttonType === PaginationButtonType.next || props.buttonType === PaginationButtonType.last
    return css`
        background: none;
        border: none;
        border-radius: 3px;
        color: ${ props.theme.colors.numberColor };
        cursor: pointer;
        display: flex;
        font-size: 1rem;
        font-weight: 600;
        height: 2.25rem;
        justify-content: center;
        margin: 0 4px;
        min-width: 2.25rem;
        outline: none;
        padding: .5rem;

        &:hover {
            background-color: ${ props.theme.colors.highlightColor };
            color: ${ props.theme.colors.white };
        }

        ${ props.disabled && css`
            cursor: not-allowed;
            opacity: 0.5;
        ` }

        ${ props.buttonType === PaginationButtonType.currentPageNumber && css`
            background-color: ${ props.theme.colors.highlightColor };
            color: ${ props.theme.colors.white };
        ` }

        ${ isLabelButton && css`
            background-color: ${ props.theme.colors.lightBlue };
            color: ${ props.theme.colors.quinary };
            font-size: 0.8rem;

            ${/* sc-sel */PaginationButtonLabelElement} {
                display: none;
            }
             &:after {
              font-family: ${ props.theme.iconCollection.fontFamily };
            }
        ` }

        ${ props.buttonType === PaginationButtonType.first && css`
            &:after {
                content: '${ props.theme.iconCollection.icons.chevronFatLeftDouble }';
                color: ${ props.theme.colors.numberColor };
                font-size: 0.5rem;
            }
        ` }

        ${ props.buttonType === PaginationButtonType.previous && css`
            &:after {
              content: '${ props.theme.iconCollection.icons.chevronFatLeft }';
              color: ${ props.theme.colors.numberColor };
              font-size: 0.5rem;
            }
        ` }

        ${ props.buttonType === PaginationButtonType.next && css`
            &:after {
              content: '${ props.theme.iconCollection.icons.chevronFatRight }';
              color: ${ props.theme.colors.numberColor };
              font-size: 0.5rem;
            }
        ` }

        ${ props.buttonType === PaginationButtonType.last && css`
            &:after {
              content: '${ props.theme.iconCollection.icons.chevronFatRightDouble }';
                color: ${ props.theme.colors.numberColor };
                font-size: 0.5rem;
            }
        ` }
    `
}
