import { ButtonVariant, ButtonProps, WithThemeProp } from '@tblg/components'
import { lighten, transparentize } from 'polished'
import { css, FlattenSimpleInterpolation } from 'styled-components'

export const MixinVariant = ({ variant, theme }: ButtonProps & WithThemeProp): FlattenSimpleInterpolation => {
    switch (variant) {

        case ButtonVariant.secondary:
            return css`
                border-color: ${ transparentize(0.75, theme.colors.buttonSecondary) };
                color: ${ theme.colors.buttonSecondary };
                font-weight: 500;
                &:hover {
                    background-color: ${ theme.colors.info };
                    color: ${ theme.colors.white };
                }
            `

        case ButtonVariant.success:
            return css`
                background-color: ${ lighten(0.5, theme.colors.success) };
                color: ${ theme.colors.success };
                font-weight: 500;
                &:hover {
                    background-color: ${ theme.colors.success };
                    color: ${ theme.colors.white };
                }
            `

        case ButtonVariant.info:
            return css`
                background-color: ${ lighten(0.35, theme.colors.info) };
                color: ${ theme.colors.info };
                font-weight: 500;
                &:hover {
                    background-color: ${ theme.colors.info };
                    color: ${ theme.colors.white };
                }
            `

        case ButtonVariant.warning:
            return css`
                background-color: ${ lighten(0.4, theme.colors.warning) };
                color: ${ theme.colors.warning };
                font-weight: 500;
                &:hover {
                    background-color: ${ theme.colors.warning };
                    color: ${ theme.colors.buttonDark };
                }
            `

        case ButtonVariant.error:
            return css`
                background-color: ${ lighten(0.35, theme.colors.error) };
                color: ${ theme.colors.error };
                font-weight: 500;
                &:hover {
                    background-color: ${ theme.colors.error };
                    color: ${ theme.colors.white };
                }
            `

        case ButtonVariant.link:
            return css`
                background-color: transparent;
                color: ${ theme.colors.buttonPrimary };
                &:hover {
                    background-color: ${ theme.colors.buttonPrimary };
                    color: ${ theme.colors.white };
                    text-decoration: underline;
                }
            `

        case ButtonVariant.primary:
        default:
            return css`
                background-color: ${ lighten(0.35, theme.colors.buttonPrimary) };
                color: ${ theme.colors.buttonPrimary };
                font-weight: 500;
                &:hover {
                    background-color: ${ theme.colors.buttonPrimary };
                    color: ${ theme.colors.white };
                }
            `

    }

}
