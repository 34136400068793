export type CookieKey = string
interface CookieKeys {
    [key: string]: CookieKey,
}

export const E2E_AUTH_STATE: CookieKey = 'e2e-auth-state' // only for e2e testing using Cypress

export const COOKIE_KEYS: CookieKeys = {
    E2E_AUTH_STATE,
}
