import { css } from 'styled-components'
import { transparentize } from 'polished'
import { SpinnerElement, LoaderProps, LoaderMessageElement, LoaderElement, WithThemeProp } from '@tblg/components'

export const Loader = (props: LoaderProps & WithThemeProp) => css`
     align-items: center;
     justify-content: center;
     display: flex;
     
     ${/* sc-sel */SpinnerElement} {
        left: calc(50% - 1.5rem);
        
        &:after {
            border: 2px solid ${ props.theme.colors.quinary };
            border-right: 0.1em solid transparent;
        }
     }
     
     ${/* sc-sel */LoaderElement} {
          align-items: center;
          display: flex;
          flex-direction: column;
          margin: 0 auto;
     }
    
     
     ${ props.overlapping && css`
        background-color: ${ transparentize(0.1, props.theme.colors.white) };
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        ${/* sc-sel */LoaderElement} {
           color: ${ props.theme.colors.text };
           padding: 1rem;
           min-width: 60%;
        }
     ` }
     
     ${/* sc-sel */LoaderMessageElement} {
        margin-top: 3rem;
     }
`
