import { createGlobalStyle } from 'styled-components'

const fontPath = '/fonts/'

export const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'icomoon';
        font-style: normal;
        font-weight: normal;
        src:    url('${ fontPath }icomoon.eot?48fzx2');
        src:    url('${ fontPath }icomoon.eot?48fzx2#iefix') format('embedded-opentype'),
                url('${ fontPath }icomoon.ttf?48fzx2') format('truetype'),
                url('${ fontPath }icomoon.woff?48fzx2') format('woff'),
                url('${ fontPath }icomoon.svg?48fzx2#icomoon') format('svg');
    }
    
    html,
    body {
        background-color: white;
        height: 100%;
        margin: 0;
        padding: 0;
        
        font-size: 13px;
        color: #646C9A;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        line-height: 1.5;
        font-kerning: normal;
        font-smooth: always; /* stylelint-disable-line */
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        text-size-adjust: 100%;
    }

    #__next {
        height: 100%;
    }
`
